@import '../../../scss/variables';
.root{
  @include background-main
}
.welcomeTitle{
  color: $primary;
  text-align: center;
  padding-top: 1.5rem;
  font-size: 2rem;
  @include up("md"){
    text-transform: uppercase;
    font-size: 7rem;
  }
}
.subWelcome{
  color: $primary;
  text-align: center;
  padding-top: 1rem;
  @include up("md"){
    text-transform: uppercase;
  }
}
.someWords{
  color: $warning;
  font-size: 4rem;
}