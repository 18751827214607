@import '../../../scss/variables';

.titleCaption{
  position: absolute;
  top:1rem;
  color: $light;
  left: 1rem;
  font-size: 1rem;
  @include up("md"){
    top:6rem;
    color: $light;
    left: 4rem;
    font-size: 3rem;
  }
}

.sectionCard{
  background-color: #ffffff91;
  color: #004862 !important;
  top: 0px;
  right: 0 !important;
  left: unset !important;
  height: 100%;
  width: 50%;
  text-align: left !important;
  padding-left: 0.5rem;
  @include up("md"){
    width: 40rem;
    right: 10% !important;
    float: right;
    left: unset !important;
    padding-left: 2rem;
  }
  .title {
    color: $primary;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0rem;
    padding-bottom: 0rem;
    @include up("md"){
      font-size: 2rem;
      margin-top: 6rem;
    }
  }
  .content {
    color: $success;
    font-size: 0.7rem;
    padding-bottom: 0rem;
    @include up("md") {
      font-size: 1.3rem;
      margin-top: 3rem;

    }
  }
  .linkReserve{
    font-size: 2rem;
    font-weight: bold;
    @include up("md"){
      font-size: 5rem;
      margin-top: 1rem;
    }
  }
}
