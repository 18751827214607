@import "../../../scss/variables";
.root{
  @include up("md"){
    height: 600px;
  }
  margin-bottom: 3rem;
  & img {
    @include up("md"){
      max-height: 600px;
    }
  }
  .row{
    padding-left: 0px;
    padding-right: 0px;
  }
  .firstImage{
    height: 300px;
    padding-bottom: 0.2rem;
    @include up("md"){
      height: 600px;
      padding-bottom: 0;
    }
    width: 100%;
    object-fit: cover;
  }
  .secondaryImageT{
    height: 400px;
    width: 100%;
    object-fit: cover;
  }
  .secondaryImageB{
    height: 200px;
    width: 100%;
    object-fit: cover;
    padding-bottom: 0.2rem;
    @include up("md"){
      padding-bottom: 0;
    }
  }
  .finalImageT{
    height: 200px;
    width: 100%;
    object-fit: cover;
  }
  .finalImageB{
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

}
