@import '../../scss/variables';
.root{
  background-color: $success;
  min-height: 10rem;
  padding-top: 3rem;;
  color: $light;
  h3{
    font-size: 1.4rem;
    font-weight: bolder;
    text-align: center;
    @include up("md"){
      text-align: left;
      font-size: 2.4rem;
    }
  }
  .detailsFooter {
    padding-left: 0rem;
    font-size: 0.8rem;
    @include up("md"){
      padding-left: 2rem;
      font-size: 1rem;
    }
    p{
      margin-bottom: 0rem;
    }
  }
  .detailsFooterSocial {
    text-align: center;
    @include up("md"){
      padding-left: 2rem;
      text-align: left;
    }
    svg{
      width: 3rem;
      font-size: 40px;
      border-radius: 3rem;
    }
  }
}