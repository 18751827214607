$primary: #0A612D;
$secondary: #5EB030;
$dark: #004862;
$warning: #F2932C;
$info: #FFD800;
$gray: #e5e5e5;
$success: #004862;
$light: #FFFFFF;
$color-background-primary: $light;
$color-background-secondary: #f1f4f8;
$img-background-main: url('../assets/images/background/Paginas_01-10.png');
$img-background-secondary: url('../assets/images/background/Portada_1-08.png');
$img-background-third: url('../assets/images/background/Portada_1-09.png');

$sizes: (
  "xs": 0px,
  "sm": 600px,
  "md-tablet": 768px,
  "md": 960px,
  "lg": 1440px,
  "xl": 1920px,
  "xxl": 2500px,
);

@mixin up($size) {
  @media (min-width: map-get($sizes, $size)) {
    @content;
  }
}
@mixin down($size) {
  @media (max-width: map-get($sizes, $size)) {
    @content;
  }
}

@mixin background-main{
  background-image: $img-background-main;
  background-position: bottom left;
  background-repeat: repeat-y;
  background-size: cover;
}


@mixin background-secondary{
  background-image: $img-background-secondary;
  background-position: bottom right;
  background-repeat: repeat-y;
  background-size: cover;
}