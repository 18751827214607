@import '../../../scss/variables';
.root{
  margin-top: 3rem;
}
.rootWhy{
  margin-bottom: 2rem;
  @include up("md"){
    border-right: dotted $warning 3px;
    maring-right: 2rem;
  }
}
.whyUsTitle{
  color: $primary;
  text-align: center;
  padding-top: 1.5rem;
  font-size: 1.5rem;
  @include up("md"){
    font-size: 2.5rem;
  }
}
.number{
  color: $gray;
  text-align: center;
  padding-top: 1rem;
  font-width: bold;
  font-size: 4rem;
  @include up("md"){
    text-transform: uppercase;
    font-size: 6rem;
  }
}
.someWords{
  color: $warning;
  font-size: 4rem;
}
.contentTwo {
  margin-top: 4rem;
  text-align: center;
  .simpleMessage {
    color: $warning;
    font-size: 3rem;
    font-weight: bold;
    margin-top: 3rem;
    text-align: initial;
    @include up("md"){
      font-size: 4.2rem;
    }
  }
}