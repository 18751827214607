@font-face {
  font-family: 'hallo_oyster';
  src: url('assets/fonts/hallo_oyster_-_personal_use-webfont.woff2') format('woff2'),
  url('assets/fonts/hallo_oyster_-_personal_use-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsblack_italic';
  src: url('assets/fonts/poppins-blackitalic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-blackitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsblack';
  src: url('assets/fonts/poppins-black-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsextrabold_italic';
  src: url('assets/fonts/poppins-extrabolditalic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-extrabolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsextrabold';
  src: url('assets/fonts/poppins-extrabold-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsbold_italic';
  src: url('assets/fonts/poppins-bolditalic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsbold';
  src: url('assets/fonts/poppins-bold-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinssemibold_italic';
  src: url('assets/fonts/poppins-semibolditalic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-semibolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinssemibold';
  src: url('assets/fonts/poppins-semibold-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsmedium_italic';
  src: url('assets/fonts/poppins-mediumitalic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-mediumitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsmedium';
  src: url('assets/fonts/poppins-medium-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsitalic';
  src: url('assets/fonts/poppins-italic-webfont.woff2') format('woff2'),
  url('assets/fonts/poppins-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}



@font-face {
  font-family: 'poppinsthin';
  src: url('assets/fonts/poppins-thin.woff2') format('woff2'),
  url('assets/fonts/poppins-thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsthin_italic';
  src: url('assets/fonts/poppins-thinitalic.woff2') format('woff2'),
  url('assets/fonts/poppins-thinitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsextralight';
  src: url('assets/fonts/poppins-extralight.woff2') format('woff2'),
  url('assets/fonts/poppins-extralight.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsextralight_italic';
  src: url('assets/fonts/poppins-extralightitalic.woff2') format('woff2'),
  url('assets/fonts/poppins-extralightitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinslight';
  src: url('assets/fonts/poppins-light.woff2') format('woff2'),
  url('assets/fonts/poppins-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinslight_italic';
  src: url('assets/fonts/poppins-lightitalic.woff2') format('woff2'),
  url('assets/fonts/poppins-lightitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'poppinsregular';
  src: url('assets/fonts/poppins-regular.woff2') format('woff2'),
  url('assets/fonts/poppins-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  font-family: poppinsmedium,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu-item{
  font-family: poppinsmedium;
}

.carousel-indicators{
  bottom: 10px;
}
.carousel-indicators button{
  border: #FFFFFF 1px solid;
  background-color: #FFFFFF;
  margin-left: 2px;
}
.visually-hidden{
  display: none;
}