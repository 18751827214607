@import "../../scss/variables";
.root{
  @include background-main
}
.title{
  @include up("md"){
    font-size: 6rem;
  }
  text-transform: uppercase;
  color: $primary;
  font-family: poppinsmedium;
  text-align: center;
}

.subtitle{
  color: $primary;
  font-family: poppinsmedium;
  text-align: center;
}

.input{
  color: $primary !important;
  font-weight: bold !important;
  &::placeholder{
    color: $primary !important;
    text-transform: uppercase;
    opacity: 0.4 !important;
  }
  background-color: #E5E5E5FF !important;
}
.button{
  text-align: center;
  color: $light;
}

.iconLocation{
  height: 5rem;
  width: 3rem;
}

.textCity{
  font-size: 1.7rem;
  color: $gray;
}