@import '../../../scss/variables';
.root{
    @include background-main;
  .row{
    background-color: transparent;
  }
  padding-bottom: 4rem;
}
.welcomeTitle{
  color: $primary;
  text-align: center;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  font-size: 2rem;
  font-weight: bolder;
  @include up("md"){
    font-size: 5rem;
  }
}

.nameTitle{
  text-align: center;
  color: $primary;
  font-size: 2rem;
  font-weight: bold;
  @include up("md"){
    font-size: 5rem;
  }
}
.nameTitle{
  text-align: center;
  color: $primary;
  font-size: 2rem;
  font-weight: bold;
}

.content{
  text-align: center;
  @include up("md"){
    text-align: left;
  }
}
.embededVideo{
  margin-top: 1rem;
  @include up("md"){
    background-color: #808080;
  }
  .row{
    justify-content: center;
  }
}

.imageService{
  height: 20rem;
  width: 20rem;
  object-fit: cover;
  align-self: flex-end;
}
.titleService{
  font-family: poppinsthin;
  font-size: 2rem;
  color: $primary;
  padding-top: 1rem;
  font-weight: lighter;
  @include up("md"){
    padding-top: unset;
    font-size: 5rem;
  }
}

.ButtonMore{
  color: $light !important;
  float: right;
}